<template>
  <div>
    <form-section :title="$t('courierSet.payment')">
      <v-row>
        <v-col>
          <select-field
            v-model="getBilling.payerType"
            :title="$t('courierSet.shippingPaymentType')"
            :filed-items="shippingPaymentTypes"
            rules="required"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="getBilling.paymentMethod"
            :title="$t('courierSet.paymentType')"
            :filed-items="paymentTypes"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getBilling.billingAccountNumber"
            :title="$t('courierSet.billingAccountNumber')"
            :hint="$t('courierSet.billingAccountNumberHint')"
            rules="numeric|required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getBilling.costsCenter"
            :title="$t('courierSet.costsCenter')"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { paymentTypes } from '@/constants/Speditors/DHL/PaymentTypes';
import { shippingPaymentTypes } from '@/constants/Speditors/DHL/ShippingPaymentTypes';
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    paymentTypes,
    shippingPaymentTypes,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getBilling() {
      return this.getConfigurationSet().billing;
    },
  },
};
</script>

<style scoped></style>
