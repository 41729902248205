export const labelTypes = [
  {
    id: 'LP',
    name: 'List przewozowy',
  },
  {
    id: 'BLP',
    name: 'Etykieta BLP w formacie PDF',
  },
  {
    id: 'LBLP',
    name: 'Etykieta BLP w formacie PDF A4',
  },
  {
    id: 'ZBLP',
    name: 'Etykieta BLP w formacie drukarek Zebra',
  },
  {
    id: 'ZBLP300',
    name: 'Etykieta BLP 300dpi w formacie drukarek Zebra',
  },
];
