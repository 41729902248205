<template>
  <div>
    <form-section :title="$t('courierSet.parcel')">
      <v-row>
        <v-col>
          <text-field
            v-model="getShipmentInfo.content"
            :title="$t('courierSet.parcelContent')"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getShipmentInfo.comment"
            :title="$t('courierSet.comment')"
            :hint="$t('courierSet.commentHint')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <text-field
            v-model="getShipmentInfo.reference"
            :title="$t('courierSet.reference')"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.additionalData')">
      <v-row>
        <v-col>
          <check-field
            v-model="getPiece.euroReturn"
            :label="$t('courierSet.euroReturn')"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getPiece.blpPieceId"
            :title="$t('courierSet.blpPieceId')"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getPiece() {
      return this.getConfigurationSet().dhlDefaultValues.piece;
    },
    getShipmentInfo() {
      return this.getConfigurationSet().dhlDefaultValues.shipmentInfo;
    },
  },
};
</script>

<style scoped></style>
