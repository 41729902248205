import { ShipmentInfo } from './ShipmentInfo';
import { Piece } from './Piece';

export const DhlDefaultValues = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  shipmentInfo: ShipmentInfo,
  piece: Piece,
  additionalServices: [],
  packagings: [],
  fieldsMappings: [],
};
