export const Piece = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  type: '',
  length: '',
  width: '',
  height: '',
  weight: '',
  quantity: '',
  nonStandard: false,
  euroReturn: false,
  blpPieceId: '',
};
