export const dhlAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: 'Zwrot pobrania (dodatkowo płatne)',
    parameters: true,
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki (dodatkowo płatne)',
    parameters: true,
    remark: '',
  },
  {
    id: 111,
    serviceId: 'DELIVERY_AFTER_17',
    name: 'Doręczenie po godzinie 17',
    description: 'Dodatkowo płatne',
    parameters: false,
    remark: '',
  },
  {
    id: 115,
    serviceId: 'SATURDAY_DELIVERY',
    name: 'Doręczenie w sobotę',
    description: 'Dodatkowo płatne',
    parameters: false,
    remark: '',
  },
  {
    id: 121,
    serviceId: 'PICKUP_ON_SATURDAY',
    name: 'Nadanie w sobotę',
    description: 'Dodatkowo płatne',
    parameters: false,
    remark: '',
  },
  {
    id: 205,
    serviceId: 'PREDELIVERY_INFORMATION',
    name: 'Informacje przed doręczeniem',
    description: 'Dodatkowo płatne',
    parameters: false,
    remark: '',
  },
  {
    id: 4,
    serviceId: 'ROD',
    name: 'Zwrot dokumentów',
    description: 'Zwrot potwierdzonych dokumentów (dodatkowo płatne)',
    parameters: false,
    remark: '',
  },
  {
    id: 119,
    serviceId: 'NEIGHBOUR_DELIVERY',
    name: 'Doręczenie do sąsiada',
    description: 'Bezpłatne',
    parameters: false,
    remark: '',
  },
  {
    id: 117,
    serviceId: 'OWN_PICKUP',
    name: 'Odbiór własny',
    description: 'Bezpłatne',
    parameters: false,
    remark: '',
  },
];
