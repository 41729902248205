export const serviceTypes = [
  { id: 'AH', name: 'Przesyłka krajowa' },
  { id: '09', name: 'Usługa Domestic 09' },
  { id: '12', name: 'Usługa Domestic 12' },
  { id: 'DW', name: 'Przesyłka z doręczeniem wieczornym' },
  { id: 'EK', name: 'Przesyłka Connect' },
  { id: 'PI', name: 'Przesyłka International' },
  { id: 'PR', name: 'Produkt Premium' },
  { id: 'CP', name: 'Przesyłka Connect Plus' },
  { id: 'CM', name: 'Przesyłka Connect Plus Pallet' },
];
